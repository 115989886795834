import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'maxLengthEllipsis',
	standalone: true,
})
export class MaxLengthEllipsisPipe implements PipeTransform {
	transform(val: string, maxLength: number) {
		if (val && val.toString().length > maxLength) {
			return val.toString().substring(0, maxLength) + '...';
		}
		return val;
	}
}
